import http from "./http-common";
  
function create(data) {
    return http.post("/ads/create", data);
  }

  function  findAll(data) {
    return http.post("/ads/findAll", data );
  }

  function findOne(data) {
    return http.get("/ads/findOne/"+data);
  }

  function findOneMasked(data) {
    return http.get("/ads/findOneMasked/"+data);
  }

  function updateStatus(_id, data) {
    return http.put("/ads/updateStatus/"+_id, data);
  }

  function countFreeAds(data) {
    return http.get("/ads/countFreeAds/"+data);
  }

  function validateOwner(data) {
    return http.post("/ads/validateOwner", data);
  }


  function getCoordinates(data) {
    return http.get("/goo/getCoordinates/"+data);
  }

  function  getTariff(date, type,) {
    return http.get("tariff/getTariff/"+date+"/"+type);
  }

  function  addContactForm(data) {
    return http.post("/services/addContactForm", data);
  }

  function  addFeedbackForm(data) {
    return http.post("/services/addFeedbackForm", data);
  }

  function  validateToken(data) {
    return http.get("security/validateToken/"+data);

  }


  function  createTransactionWebPayPlus(data) {
    return http.post("/wpp/createTransaction", data );
  }

  function  commitTransactionWebPayPlus(data) {
    return http.post("/wpp/commitTransaction", data );
  }

  function requestForNotification(data) {
    return http.post("notificationRequest/saveNotificationRequest", data)
  }

const DataServiceForAds = {
  create,
  findAll,
  findOne,
  findOneMasked,
  updateStatus,
  countFreeAds,
  validateOwner,
  getCoordinates,
  getTariff,
  addContactForm,
  addFeedbackForm,
  validateToken,
  createTransactionWebPayPlus,
  commitTransactionWebPayPlus,
  requestForNotification
};

export default DataServiceForAds;