import * as React from "react";
import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import Sidenav from "../Sidenav";
import Mural from "../dashboard/Mural";
import Banner from "../others/banner";
import Launch from "../others/launch";


export default function Home() {
  const query = new URLSearchParams(useLocation().search);
  const banner = (query.get('banner')? (query.get('banner')==="false"?false:true ):true) ;
  const id = (query.get('id')? query.get('id'): "-1") ;
  const category = (query.get('categoria')? query.get('categoria'): "-1") ;


  const [refreshMural, setRefreshMural] = React.useState(false);

  const onRefreshHome = () => {
    //console.log(":-O  se enciende refresh");
    setRefreshMural(true);
  };

  const offRefreshHome = () => {
    //console.log(":-O  se apaga refresh");
    setRefreshMural(false);
  };

  return (
    <>
      <Box sx={{ height: 15 }}></Box>
      <Sidenav  page={"Home"} onRefreshHome={onRefreshHome}/>
      <Mural id={id} category={category} refreshMural={refreshMural} offRefreshHome={offRefreshHome}/>
      {process.env.REACT_APP_CE_BANNER === "true" && id === "-1" &&  category ==="-1" && banner?
        <Banner />
      :
      ""}
      {process.env.REACT_APP_CE_LAUNCH === "true" && id === "-1" &&  category ==="-1"?
      <Launch />
      :
      ""}
      <Box sx={{ height: 70 }}></Box>
    </>
  );
}
