//import * as React from "react";
import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import RefreshIcon from "@mui/icons-material/Refresh";
import { NotificationAdd } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";

import Grid from "@mui/material/Unstable_Grid2";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import MoodIcon from "@mui/icons-material/Mood";
import { green, blue } from "@mui/material/colors";

import RecordByPage from "./RecordByPage";
import MyFeedback from "../others/Feedback";
import { isValidPhone } from "../utils/utils";

import "./Ads.css";

import {
  States,
  Cities,
  Categories,
  Agreements,
  KindsOf,
  emptyFilter,
} from "../services/dbAds";

import service from "../services";
import { Favorite } from "@mui/icons-material";
import EnableGpsIcon from "./EnableGpsIcon";
import EnableRRSSIcon from "./EnableRRSSIcon";
import { Paper } from "@mui/material";

const fabStyle = {
  position: "fixed",
  bottom: 16,
  left: 16,
};

const liStyle = {
  listStyleType: "none",
  padding: "5px",
  margin: "3px",
};

export default function FloatingActionFilters(props) {
  const [openSubscribe, setOpenSubscribe] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [openSetting, setOpenSetting] = React.useState(false);
  const [openFavorite, setOpenFavorite] = React.useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [errorEmail, setErrorEmail] = React.useState(false);
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (event.target.value.length > 0) setErrorEmail(false);
  };

  const [mensaje, setMensaje] = React.useState("");
  const [errorMensaje, setErrorMensaje] = React.useState(false);
  const handleMensajeChange = (event) => {
    setMensaje(event.target.value);
    if (event.target.value.length > 0) setErrorMensaje(false);
  };

  const [openFeedback, setopenFeedback] = React.useState(false);
  const handleClickopenFeedback = () => {
    setopenFeedback(true);
  };
  const receiveOpenFeedback = () => {
    setopenFeedback(false);
  };

  const handleClickOpenSubscribe = () => {
    setOpenSubscribe(true);
  };

  const handleCloseSubscribe = () => {
    setErrorEmail(false);
    setErrorMensaje(false);
    setOpenSubscribe(false);
  };

  const handleSendSubscribe = () => {
    if (email.length === 0) {
      setErrorEmail(true);
      return;
    } else {
      console.log("validar formato email");
    }
    if (mensaje.length === 0) {
      setErrorMensaje(true);
      return;
    }

    setErrorEmail(false);
    setErrorMensaje(false);

    var data = { email: email, mensaje: mensaje, fechaContacto: dayjs() };

    service
      .addContactForm({ data })
      .then((response) => {
        console.log(response);

        if (response.data.status === 200) {
          console.log("Contacto grabado");
        } else {
          console.log("No pude grabar el contacto");
        }
      })
      .catch((error) => {
        console.log(error.message);
      });

    setOpenSubscribe(false);

    handleOpenAlert();
  };

  const [cities, setCities] = React.useState([]);
  const [products, setProducts] = React.useState([]);

  const [errorRegion, setErrorRegion] = React.useState(false);
  const [region, setRegion] = React.useState("");

  const [errorComuna, setErrorComuna] = React.useState(false);
  const [comuna, setComuna] = React.useState("");

  const [errorTelefono, setErrorTelefono] = React.useState(false);
  const [telefono, setTelefono] = React.useState("");
  const handleTelefonoChange = (event) => {
    setTelefono(event.target.value);
    if (event.target.value.length > 0) setErrorTelefono(false);
  };

  const handleRegionChange = (event) => {
    setRegion(event.target.value);
    setComuna("");
    setCities(Cities.filter((city) => city.region === event.target.value));
  };
  const handleComunaChange = (event) => {
    setComuna(event.target.value);
  };

  // validaciones informacion categoria
  const [errorCategoria, setErrorCategoria] = React.useState(false);
  const [categoria, setCategoria] = React.useState("");
  const handleCategoriaChange = (event) => {
    setCategoria(event.target.value);
    setProducts(
      KindsOf.filter((kindOf) => kindOf.Category === event.target.value)
    );
  };

  const [errorOperacion, setErrorOperacion] = React.useState(false);
  const [operacion, setOperacion] = React.useState("");
  const handleOperacionChange = (event) => {
    setOperacion(event.target.value);
  };

  const [errorProducto, setErrorProducto] = React.useState(false);
  const [producto, setProducto] = React.useState("");
  const handleProductoChange = (event) => {
    setProducto(event.target.value);
  };

  const [estadoProducto, setEstadoProducto] = React.useState("");
  const handleRadioEstadoProducto = (event) => {
    setEstadoProducto(event.target.value);
  };

  const [errorTextoAnuncio, setErrorTextoAnuncio] = React.useState(false);
  const [textoAnuncio, setTextoAnuncio] = React.useState("");
  const handleTextoAnuncioChange = (event) => {
    setTextoAnuncio(event.target.value);
  };

  const handleClickOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleCleanFilter = () => {
    if (
      region ||
      comuna ||
      categoria ||
      operacion ||
      producto ||
      estadoProducto ||
      textoAnuncio
    ) {
      props.onFilters({});
      setRegion("");
      setComuna("");
      setCategoria("");
      setEstadoProducto("");
      setProducto("");
      setOperacion("");
      setTextoAnuncio("");
      props.onFlagFilter(false);
    }
    setOpenFilter(false);
  };

  const handleApplyFilter = () => {
    if (
      region ||
      comuna ||
      categoria ||
      operacion ||
      producto ||
      estadoProducto ||
      textoAnuncio
    ) {
      props.onFilters({
        region: region,
        comuna: comuna,
        categoria: categoria,
        operacion: operacion,
        producto: producto,
        estadoProducto: estadoProducto,
        textoAnuncio: textoAnuncio,
      });
      props.onFlagFilter(true);
    }

    setOpenFilter(false);
  };

  const handleClickOpenNotification = () => {
    handleCleanNotification();
    setOpenNotification(true);
  };

  const handleCloseNotification = () => {
    setOpenNotification(false);
  };

  const handleCleanNotification = () => {
    if (
      region ||
      comuna ||
      categoria ||
      operacion ||
      producto ||
      estadoProducto ||
      textoAnuncio ||
      telefono
    ) {
      //props.onFilters({});
      setRegion("");
      setComuna("");
      setCategoria("");
      setEstadoProducto("");
      setProducto("");
      setOperacion("");
      setTextoAnuncio("");
      setTelefono("");

    }
    //setOpenNotification(false);
  };

  const handleApplyNotification = () => {
    // informacion de contacto
    if (telefono.length === 0) {
      setErrorTelefono(true);
      return;
    } else {
      if (!isValidPhone(telefono)) {
        setErrorTelefono(true);
        return;
      }
    }

      // informacion de la categoria
      if (categoria.length === 0) {
        setErrorCategoria(true);
        return;
      }
      if (operacion.length === 0) {
        setErrorOperacion(true);
        return;
      }
      if (producto.length === 0) {
        setErrorProducto(true);
        return;
      }

    if (
      region ||
      comuna ||
      categoria ||
      operacion ||
      producto ||
      textoAnuncio ||
      telefono
    ) {
      props.onNotifications({
        region: region,
        comuna: comuna,
        categoria: categoria,
        operacion: operacion,
        producto: producto,
        textoAnuncio: textoAnuncio,
        telefono: telefono,
      });
    }

    setOpenNotification(false);
  };

  const handleClickOpenSetting = () => {
    setOpenSetting(true);
  };

  const handleCloseSetting = () => {
    setOpenSetting(false);
  };

  const handleClickOpenFavorite = () => {
    setOpenFavorite(true);
  };

  const handleCloseFavorite = () => {
    setOpenFavorite(false);
  };

  const handleCleanFavorite = () => {
    props.onCleanFavorites();
    setOpenFavorite(false);
  };

  const [enableGpsIcon, setEnableGpsIcon] = React.useState(props.enableGpsIcon); // contiene valor actual de enableGpsIcon
  const [nuevoEnableGpsIcon, setNuevoEnableGpsIcon] = React.useState(
    props.enableGpsIcon
  ); // contiene valor actual de enableGpsIcon
  const recibirNuevoEnableGpsIcon = (enableDisable) => {
    setNuevoEnableGpsIcon(enableDisable);
  };

  const [enableRRSSIcon, setEnableRRSSIcon] = React.useState(
    props.enableRRSSIcon
  ); // contiene valor actual de enableGpsIcon
  const [nuevoEnableRRSSIcon, setNuevoEnableRRSSIcon] = React.useState(
    props.enableRRSSIcon
  ); // contiene valor actual de enableGpsIcon
  const recibirNuevoEnableRRSSIcon = (enableDisable) => {
    setNuevoEnableRRSSIcon(enableDisable);
  };

  const [recordByPage, setRecordByPage] = React.useState(props.pageSize); // contiene valor actual de recordByPage
  const [nuevoRecordByPage, setNuevoRecordByPage] = React.useState(
    props.pageSize
  ); // contiene valor retornado por widget recordByPage

  const handleCancelNewConfig = () => {
    setOpenSetting(false);
    // volver recordByPage a su valor original
    setRecordByPage(props.pageSize);
  };

  const handleAplyNewConfig = () => {
    setOpenSetting(false);
    // se debe actualizar valor recordByPage o pageSize en componente Mural
    setRecordByPage(nuevoRecordByPage);
    props.onPageSize(nuevoRecordByPage);

    setEnableGpsIcon(nuevoEnableGpsIcon);
    props.onEnableGpsIcon(nuevoEnableGpsIcon);

    setEnableRRSSIcon(nuevoEnableRRSSIcon);
    props.onEnableRRSSIcon(nuevoEnableRRSSIcon);
  };

  const recibirNuevoRecordByPage = (recordByPage) => {
    setNuevoRecordByPage(recordByPage);
  };

  const handleClickRefreshPage = () => {
    props.onRefresh();
  };

  const actions = [
    {
      id: "setting",
      icon: <SettingsIcon color="action" />,
      name: "Configuracion de pagina",
      onclick: handleClickOpenSetting,
    },

    {
      id: "favorite",
      icon: <Favorite color="action" />,
      name: "Ver Favoritos",
      onclick: handleClickOpenFavorite,
    },
    {
      id: "filter",
      icon: <FilterAltIcon color="action" />,
      name: "Filtrar anuncios",
      onclick: handleClickOpenFilter,
    },
    {
      id: "email",
      icon: <EmailIcon color="action" />,
      name: "Contacto",
      onclick: handleClickOpenSubscribe,
    },
    /*    
    {
      id: "feedback",
      icon: <Feedback color="action" />,
      name: "Danos tu feedback",
      onclick: handleClickopenFeedback,
    },
    */
    /*
    {
      id: "notificacion",
      icon: <NotificationAdd color="action" />,
      name: "Agregar notificacion",
      onclick: handleClickOpenNotification,
    },
    */
    {
      id: "refresh",
      icon: <RefreshIcon color="action" />,
      name: "Refresca la pagina",
      onclick: handleClickRefreshPage,
    },
  ];

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [openAlert, setOpenAlert] = React.useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const viewAds = (anuncio) => {
    props.onViewAds(anuncio);
  };

  function objetoVacio(objeto) {
    return Object.keys(objeto).length === 0;
  }
  useEffect(() => {
    if (props.initFilters) {
      setCategoria(props.filters.categoria);
      setOpenFilter(true);
      props.onFlagFilter(true);
    }
  }, [props.initFilters]);

  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: "100%" }}
        >
          Formulario de contacto ingresado — Pronto nos comunicaremos con usted!
        </Alert>
      </Snackbar>

      <MyFeedback
        open={openFeedback}
        onReceiveOpenFeedback={receiveOpenFeedback}
        pantalla={props.pantalla}
      />

      <Box style={fabStyle}>
        <SpeedDial
          color="secondary"
          ariaLabel="Opciones"
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) =>
            action.id === "filter" ? (
              <SpeedDialAction
                key={action.name}
                icon={
                  props.flagFilter ? (
                    <FilterAltIcon color="success" />
                  ) : (
                    <FilterAltIcon color="action" />
                  )
                }
                tooltipTitle={
                  props.flagFilter
                    ? "Anuncios filtrados" + "(" + props.ads + ")"
                    : action.name
                }
                onClick={action.onclick}
              />
            ) : action.id === "favorite" ? (
              <SpeedDialAction
                key={action.name}
                icon={
                  props.favorites.length > 0 ? (
                    <Favorite color="error" />
                  ) : (
                    <Favorite color="action" />
                  )
                }
                tooltipTitle={action.name + "(" + props.favorites.length + ")"}
                onClick={action.onclick}
              />
            ) : (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.onclick}
              />
            )
          )}
        </SpeedDial>

        <Dialog open={openSubscribe} onClose={handleCloseSubscribe}>
          <DialogTitle component="span">
            <Typography color="textPrimary" variant="h5" gutterBottom>
              Contacto
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText component="span">
              Para comunicarse con ClasificadosExpress.Cl, envienos su inquietud
              y lo contactaremos a la brevedad.
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Direccion Email"
                type="email"
                fullWidth
                variant="standard"
                required
                helperText={errorEmail ? "Debe ingresar un e-mail valido" : ""}
                error={errorEmail}
                onChange={handleEmailChange}
              />
              <TextField
                autoFocus
                margin="dense"
                id="mensaje"
                label="Mensaje"
                type="text"
                fullWidth
                variant="standard"
                required
                helperText={errorMensaje ? "Debe ingresar un mensaje" : ""}
                error={errorMensaje}
                onChange={handleMensajeChange}
              />
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseSubscribe} variant="outlined">
              Cancelar
            </Button>
            <Button onClick={handleSendSubscribe} variant="outlined">
              Enviar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openFilter}
          fullScreen={fullScreen}
          onClose={handleCloseFilter}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" component="span">
            <Typography color="textPrimary" variant="h5" gutterBottom>
              Filtros de Busqueda
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" component="span">
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <FormControl sx={{ m: 1, width: "95%" }}>
                    <InputLabel id="region-helper-label">Region</InputLabel>
                    <Select
                      labelId="region-helper-label"
                      id="region"
                      value={region}
                      label="Region"
                      displayEmpty
                      onChange={handleRegionChange}
                      variant="filled"
                    >
                      {States.map((state) => (
                        <MenuItem key={state.id} value={state.id}>
                          {state.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12}>
                  <FormControl sx={{ m: 1, width: "95%" }}>
                    <InputLabel id="city-helper-label">Comuna</InputLabel>
                    <Select
                      labelId="city-helper-label"
                      id="city"
                      value={comuna}
                      label="Comuna"
                      onChange={handleComunaChange}
                      displayEmpty
                      variant="filled"
                    >
                      {cities.map((city) => (
                        <MenuItem key={city.id} value={city.nombre}>
                          {city.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid xs={12}>
                  <FormControl sx={{ m: 1, width: "95%" }}>
                    <InputLabel id="region-helper-label">Categoria</InputLabel>
                    <Select
                      labelId="category-helper-label"
                      id="category"
                      value={categoria}
                      label="Categoria"
                      displayEmpty
                      onChange={handleCategoriaChange}
                      variant="filled"
                    >
                      {Categories.map((category) => (
                        <MenuItem key={category.id} value={category.name}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid xs={12}>
                  <FormControl sx={{ m: 1, width: "95%" }}>
                    <InputLabel id="kindof-helper-label">Tipo</InputLabel>
                    <Select
                      labelId="kindof-helper-label"
                      id="kindof"
                      value={producto}
                      label="Tipo"
                      onChange={handleProductoChange}
                      displayEmpty
                      variant="filled"
                    >
                      {products.map((product) => (
                        <MenuItem key={product.id} value={product.name}>
                          {product.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid xs={12}>
                  <FormControl sx={{ m: 1, width: "95%" }}>
                    <InputLabel id="agreement-helper-label">
                      Operacion
                    </InputLabel>
                    <Select
                      labelId="agreement-helper-label"
                      id="agreement"
                      value={operacion}
                      label="Operacion"
                      onChange={handleOperacionChange}
                      displayEmpty
                      variant="filled"
                    >
                      {Agreements.map((agreement) => (
                        <MenuItem key={agreement.id} value={agreement.name}>
                          {agreement.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid xs={12}>
                  <FormControl sx={{ m: 1, width: "95%" }}>
                    <FormLabel id="estado-group-label">Estado</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="estado-group-label"
                      name="row-radio-buttons-group"
                      onChange={handleRadioEstadoProducto}
                      value={estadoProducto}
                    >
                      <FormControlLabel
                        value="Nuevo"
                        control={<Radio />}
                        label="Nuevo"
                      />
                      <FormControlLabel
                        value="Usado"
                        control={<Radio />}
                        label="Usado"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid xs={12}>
                  <TextField
                    value={textoAnuncio}
                    id="textoAnuncio"
                    label="Texto Anuncio"
                    variant="filled"
                    sx={{ m: 1, width: "95%" }}
                    onChange={handleTextoAnuncioChange}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseFilter} variant="outlined">
              Cancelar
            </Button>
            <Button onClick={handleCleanFilter} variant="outlined">
              Limpiar
            </Button>
            <Button onClick={handleApplyFilter} autoFocus variant="outlined">
              Aplicar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openNotification}
          fullScreen={fullScreen}
          onClose={handleCloseNotification}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" component="span">
            <Typography color="textPrimary" variant="h5" gutterBottom>
              Ingresa un filtro y te notificaremos cuando publiquen lo que estas
              buscando
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" component="span">
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <FormControl
                    sx={{ m: 1, width: "95%" }}
                    error={errorRegion}
                  >
                    <InputLabel id="region-helper-label">Region</InputLabel>
                    <Select
                      labelId="region-helper-label"
                      id="region"
                      value={region}
                      label="Region"
                      displayEmpty
                      onChange={handleRegionChange}
                      variant="filled"
                    >
                      {States.map((state) => (
                        <MenuItem key={state.id} value={state.id}>
                          {state.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errorRegion ? "Debe seleccionar una region" : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={12}>
                  <FormControl sx={{ m: 1, width: "95%" }} error={errorComuna}>
                    <InputLabel id="city-helper-label">Comuna</InputLabel>
                    <Select
                      labelId="city-helper-label"
                      id="city"
                      value={comuna}
                      label="Comuna"
                      onChange={handleComunaChange}
                      displayEmpty
                      variant="filled"
                    >
                      {cities.map((city) => (
                        <MenuItem key={city.id} value={city.nombre}>
                          {city.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errorComuna ? "Debe seleccionar una comuna" : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid xs={12}>
                  <FormControl required sx={{ m: 1, width: "95%" }} error={errorCategoria}>
                    <InputLabel id="region-helper-label">Categoria</InputLabel>
                    <Select
                      labelId="category-helper-label"
                      id="category"
                      value={categoria}
                      label="Categoria"
                      displayEmpty
                      onChange={handleCategoriaChange}
                      variant="filled"
                    >
                      {Categories.map((category) => (
                        <MenuItem key={category.id} value={category.name}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errorCategoria ? "Debe seleccionar una categoria" : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid xs={12}>
                  <FormControl required sx={{ m: 1, width: "95%" }} error={errorProducto}>
                    <InputLabel id="kindof-helper-label">Tipo</InputLabel>
                    <Select
                      labelId="kindof-helper-label"
                      id="kindof"
                      value={producto}
                      label="Tipo"
                      onChange={handleProductoChange}
                      displayEmpty
                      variant="filled"
                    >
                      {products.map((product) => (
                        <MenuItem key={product.id} value={product.name}>
                          {product.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errorProducto ? "Debe seleccionar un tipo de producto" : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid xs={12}>
                  <FormControl required sx={{ m: 1, width: "95%" }} error={errorOperacion}>
                    <InputLabel id="agreement-helper-label">
                      Operacion
                    </InputLabel>
                    <Select
                      labelId="agreement-helper-label"
                      id="agreement"
                      value={operacion}
                      label="Operacion"
                      onChange={handleOperacionChange}
                      displayEmpty
                      variant="filled"
                    >
                      {Agreements.map((agreement) => (
                        <MenuItem key={agreement.id} value={agreement.name}>
                          {agreement.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errorOperacion ? "Debe seleccionar una operacion" : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid xs={12}>
                  <TextField
                    value={textoAnuncio}
                    id="textoAnuncio"
                    label="Texto Anuncio"
                    variant="filled"
                    sx={{ m: 1, width: "95%" }}
                    onChange={handleTextoAnuncioChange}
                    error={errorTextoAnuncio}
                  />
                  <FormHelperText>
                    {errorTextoAnuncio
                      ? "Debe ingresar el texto de busqueda"
                      : ""}
                  </FormHelperText>
                </Grid>

                <Grid xs={12}>
                  <TextField
                    required
                    value={telefono}
                    id="telefono"
                    label="Telefono de Contacto"
                    variant="filled"
                    sx={{ m: 1, width: "100%" }}
                    onChange={handleTelefonoChange}
                    helperText={
                      errorTelefono
                        ? "Debe ingresar un numero de telefono valido"
                        : ""
                    }
                    error={errorTelefono}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+56</InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText>
                    {errorTelefono
                      ? "Debe seleccionar un telefono de contacto"
                      : ""}
                  </FormHelperText>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNotification} variant="outlined">
              Cancelar
            </Button>
            <Button onClick={handleCleanNotification} variant="outlined">
              Limpiar
            </Button>
            <Button
              onClick={handleApplyNotification}
              autoFocus
              variant="outlined"
            >
              Aplicar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openSetting}
          onClose={handleCloseSetting}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" component="span">
            <Typography color="textPrimary" variant="h5" gutterBottom>
              Configuracion de pagina
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" component="span">
              <Paper>
                <RecordByPage
                  recordByPage={recordByPage}
                  onRecordByPage={recibirNuevoRecordByPage}
                />

                <EnableGpsIcon
                  enableGpsIcon={enableGpsIcon}
                  onEnableGpsIcon={recibirNuevoEnableGpsIcon}
                />

                <EnableRRSSIcon
                  enableRRSSIcon={enableRRSSIcon}
                  onEnableRRSSIcon={recibirNuevoEnableRRSSIcon}
                />
              </Paper>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelNewConfig} variant="outlined">
              Cancelar
            </Button>
            <Button onClick={handleAplyNewConfig} autoFocus variant="outlined">
              Aplicar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openFavorite}
          onClose={handleCloseFavorite}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" component="span">
            <Typography color="textPrimary" variant="h5" gutterBottom>
              Mis Favoritos
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" component="span">
              <Paper>
                {props.favorites.length > 0 ? (
                  props.favorites.map((favorite) => (
                    <li key={favorite._id} style={liStyle}>
                      <div
                        className="FavoriteAds"
                        onClick={() => viewAds(favorite._id)}
                      >
                        <Typography variant="body1" gutterBottom>
                          {favorite.tipoAnuncio === "premium"
                            ? favorite.tituloAnuncio + ". "
                            : ""}
                          {favorite.textoAnuncio}
                          {favorite.tipoAnuncio === "premium"
                            ? ". " + favorite.pieAnuncio
                            : ""}
                          {". "}
                          {favorite.comuna}
                          {favorite.usarGPS && props.enableGpsIcon ? (
                            <LocationOnIcon
                              fontSize="small"
                              sx={{ color: green[500] }}
                            />
                          ) : (
                            ""
                          )}
                          {favorite.usarRRSS && props.enableRRSSIcon ? (
                            <MoodIcon
                              fontSize="small"
                              sx={{ color: blue[500] }}
                            />
                          ) : (
                            ""
                          )}

                          <ZoomInIcon fontSize="small" color="primary" />
                        </Typography>

                        {dayjs(favorite.fechaFin).isBefore(dayjs()) ? (
                          <>
                            <Typography
                              variant="body1"
                              gutterBottom
                              sx={{ color: "red" }}
                            >
                              Aviso Expirado.
                            </Typography>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </li>
                  ))
                ) : (
                  <Typography variant="body1" gutterBottom>
                    AUN NO TIENES FAVORITOS.
                  </Typography>
                )}
              </Paper>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {props.favorites.length > 0 ? (
              <Button
                onClick={handleCleanFavorite}
                startIcon={<CleaningServicesIcon />}
                variant="outlined"
              >
                Limpiar
              </Button>
            ) : (
              ""
            )}

            <Button
              onClick={handleCloseFavorite}
              autoFocus
              startIcon={<CloseIcon />}
              variant="outlined"
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}
